@use '../../../base_css/abstracts/abstracts' as *;

.cta-info-card {
  height: max-content;
  padding: 24px;
  background-color: var(--c_white);
  line-height: 20px;

  &__countdown {
    @include fontSize(18);
    display: inline-block;
    padding: 10px;
    background-color: var(--c_blue_green_100);
    font-weight: 700;
  }

  &__checkboxes-list {
    margin-bottom: 20px;

    .item-list {
      @include icon(check, before, 'm') {
        margin-right: 10px;
        color: var(--c_primary);
      }
    }
  }

  &__button {
    margin-right: $space3;

    &:last-child {
      margin-right: 0;
    }

    &.ocean {
      background-color: var(--c_ocean);
    }

    &.cosmos {
      background-color: var(--c_blue_700);

      &:hover:not(.disabled, :disabled),
      &:focus:not(.disabled, :disabled) {
        background-color: rgba(var(--c_blue_700_rgb), 0.8);
      }
    }

    &.black {
      background-color: var(--c_black);
    }

    &.white {
      background-color: var(--c_white);
      color: var(--c_black);
    }
  }

  &.last-searches {
    .hotel-name {
      color: var(--c_grey_600);
      line-height: 1.1;
    }

    .rating-stars::before {
      padding-bottom: 10px;
      color: var(--c_grey_600);
    }

    .secondary-btn {
      margin-left: 0;
    }
  }

  // Class for change color by user in CMS
  &--blue {
    width: 400px;
    padding: 24px;
    background-color: var(--c_blue_700);
    color: var(--c_white);
    line-height: 20px;

    .cta-info-card__countdown {
      background-color: var(--c_white);
      color: var(--c_black_grey_700);
    }

    .item-list {
      @include icon(check, before, 'm') {
        color: var(--c_white);
      }
    }

    .btn-primary {
      border: 1px solid var(--c_white);
      background-color: var(--c_white);
      color: var(--c_primary);

      &:hover:not(.disabled, :disabled),
      &:focus:not(.disabled, :disabled) {
        background-color: rgba(0, 0, 0, 0%);
        color: var(--c_white);
      }

      &.cosmos {
        background-color: rgba(0, 0, 0, 0%);
        color: var(--c_white);

        &:hover:not(.disabled, :disabled),
        &:focus:not(.disabled, :disabled) {
          background-color: rgba(0, 0, 0, 20%);
        }
      }
    }

    .secondary-btn {
      margin-left: 10px;
      background-color: var(--c_white);
    }
  }
}

@include tabletStyles {
  .cta-info-card {
    &__button {
      margin-bottom: $space3;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
