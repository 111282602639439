@use './home_megabanner_slider_commons' as *;
@use './cta_info_card/cta_info_card';

.home-megabanner-slider {
  grid-template-areas:
    'slider'
    'fastbooking'
    'lastsearches';

  &.margin-extra {
    margin-bottom: 40px;
  }

  &__slider {
    position: relative;
    width: 100%;
    height: calc(100vh - #{$space5});
  }

  &__item {
    align-items: center;
    width: 100%;
    height: 100%;

    &.swiper-slide {
      display: flex;
    }
  }

  &__info {
    position: relative;
    z-index: 20;
    display: flex;
    width: 100%;
    padding: 0 65px;

    &__pagination {
      bottom: 82px;
    }

    // Classes for card position and change with javascript
    &--middle {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &--right {
      display: flex;
      justify-content: end;
      width: 100%;
    }
  }

  .nav-btn-prev,
  .nav-btn-next {
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    margin: 0;
    border: 0;
    background-color: rgba(255, 255, 255, 60%);

    &:hover {
      background-color: var(--c_white);
      color: var(--c_primary);
    }
  }

  .nav-btn-prev {
    left: 0;
    margin-left: $space3;
  }

  .nav-btn-next {
    right: 0;
    margin-right: $space3;
  }

  &__fastbooking {
    z-index: 9;
    width: 100%;
    padding: 0;
    margin-top: -85px;
  }
}

@include tabletStyles {
  .home-megabanner-slider{
    &__slider {
      height: calc(70vh - 40px);
    }

    &__pagination {
      bottom: 26px;
    }

    &__controller {
      display: none;
    }
  }
}

@include desktopStyles {
  .home-megabanner-slider {
    $self: &;
    margin-top: -125px;

    &--ibp {
      #{ $self }__fastbooking {
        margin-top: -40px;
      }
    }
  }
}

@include tabletStyles {
  .home-megabanner-slider {
    display: block;
  }
}
