@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.home-megabanner-slider {
  position: relative;
  display: grid;

  &__slider {
    grid-area: slider;
    padding: 0;
    overflow: hidden;
  }

  &__item {
    grid-area: image;
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    &-item {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    grid-area: ctacard;
  }

  &__pagination {
    position: relative;
    bottom: 82px;
    z-index: 1;
    display: flex;
    justify-content: center;

    .swiper-pagination-bullet {
      opacity: 1;
      width: 8px;
      height: 8px;
      margin: 0 2px 0 3px;
      border: 1px solid var(--c_primary);
      border-radius: 50px;
      background: var(--c_white);

      &.swiper-pagination-bullet-active {
        background: var(--c_primary);
      }
    }
  }

  &__fastbooking {
    grid-area: fastbooking;
  }

  &__last-searches {
    grid-area: lastsearches;
    overflow: hidden;
  }

  .corporative-card {
    color: var(--c_white);
  }
}

.no-transition * {
  transition-duration: 0s !important;
}
