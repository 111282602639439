@use '../../../base_css/abstracts/abstracts' as *;
@use './cta_info_card_commons';

.cta-info-card {
  width: 400px;

  &__header {
    .title {
      margin-bottom: 10px;
      color: var(--c_grey_600);
    }

    .subtitle,
    .pretitle {
      margin-bottom: 15px;
    }
  }

  &__checkboxes-list {
    margin-bottom: 20px;
  }

  .secondary-btn {
    margin-left: 10px;
    border: 1px solid var(--c_primary);
    background-color: var(--c_white);
  }
}

@include tabletStyles {
  .cta-info-card {
    width: 50%;

    &--blue {
      width: 50%;
    }
  }
}
